
.App {
  text-align: center;
  }

  @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
    html {
      /* transform: rotate(-90deg);
      transform-origin: left top;
      width: 100vh; */
      overflow-x: hidden;
      /* position: absolute; */
      /* top: 100%; */
      /* left: 0; */
    }
  }
body {
  overflow-x: hidden;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.centeralign {
  /* text-align: center; */
  /* width: 300px; */
  /* height: 300px; */
  /* position: absolute;
  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%); */
}

.centeralignlogin {
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
  /* align-items: center; */
  /* text-align: center; */
  /* min-height: 100vh; */
  /* min-width: 500px; */
}


.loginfield {
  /* width: 300px */
}